import React from 'react'
import { Helmet } from 'react-helmet'
import AdminDashboardLayout from '../components/AdminDashboardLayout'

import ImpersonateUserTable from '../tables/ImpersonateUserTable'
import { Storage } from '../../login/Storagesetting'
import DashboardLayout from '../../components/DashboardLayout'
const ImpersonateUser = () => {
    const roleId = Storage.getItem("roleIDs")

    const Layout = roleId === '1' ? AdminDashboardLayout : DashboardLayout;
    return (
        <>
            <Helmet><title>Impersonate User</title></Helmet>
            <Layout >
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li className="breadcrumb-item active">Impersonate User</li>
                    </ol>
                </nav>

                <section className="section dashboard adminDashboard">
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='datatable'>
                                <h4 className='section_top_heading'>Impersonate User</h4>
                                {/* ApprovedUserTable tableData */}
                                <ImpersonateUserTable />
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default ImpersonateUser
