import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import { FilterMatchMode, FilterService } from "primereact/api";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { InputText } from "primereact/inputtext";
import "primeicons/primeicons.css";
import moment from "moment";
import { APIURL } from "../constant";
import { Storage } from "../login/Storagesetting";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import ExportDashboardViewDetails from "../components/ExportDashboardViewDetails";
import { TailSpin } from "react-loader-spinner";
import ExportallrecordDashboardEditDetails from "../components/ExportallrecordDashboardEditDetails";
import ExportAllRecordsDashboardViewDetails from "../components/ExportAllRecordsDashboardViewDetails";
import ErrorsLogApi from "../components/ErrorslogApi";


const ExportAllRecordsTable = ({ tabDepId }) => {
  const useId = Storage.getItem("userID");
  const rollId = Storage.getItem("roleIDs");
  const roleID = Storage.getItem("roleIDs");
  const roleName = Storage.getItem("roleName");
  const bankId = Storage.getItem("bankID")
  const {ErrorlogData} = ErrorsLogApi();

  const [exportdata, setexportdata] = useState([]);
  const [showEditForm, setshowEditForm] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [applicationDetail, setApplicationDetail] = useState({});
  const [applicationmessage, setApplicationmessage] = useState("");
  const [searchText, setSearchText] = useState("");
  const [allcomment, setallcomment] = useState([]);
  const [applicationstaus, setapplicationstaus] = useState(
    applicationDetail?.analystRecommendation
      ? `${applicationDetail?.analystRecommendation}`
      : "0"
  );
  const [nextlevelvalue, setnextlevelvalue] = useState("");
  const [userRole, setUserrole] = useState([]);
  const [tatHistory, setTatHistory] = useState([]);
  const [asignUser, setAsignUser] = useState([]);
  const [SupervisorRoleId, setSupervisorRoleId] = useState("");
  const [AssignUserID, setAssignUserID] = useState("");
  const [Actiondata, setActiondata] = useState([]);
  const [selectedAppliation, setSelectedAppliation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [checkdeligateuser, setcheckdeligateuser] = useState(0);
  const [data, setData] = useState([]);
  const [delegateAsignUser, setDelegateAsignUser] = useState([]);
  const [noDataComment, setNoDataComment] = useState([]);
  const [showdataLoader, setshowdataloader] = useState(false);
  const [responceCount, setresponceCount] = useState([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    companyName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    bankName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    tinNumber: { value: null, matchMode: FilterMatchMode.IN },
  });
  const [viewotherpopup, setviewotherpopup] = useState(false);

  FilterService.register("custom_activity", (value, filters) => {
    const [from, to] = filters ?? [null, null];
    if (from === null && to === null) return true;
    if (from !== null && to === null) return from <= value;
    if (from === null && to !== null) return value <= to;
    return from <= value && value <= to;
  });

  const [role, setRole] = useState("")

  useEffect(() => {
    setRole(applicationDetail?.assignedToRoleID)
  }, [applicationDetail])

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </span>        
      </div>
    );
  };

  const action = (rowData) => {
    return (
      <>
        <i
          className="pi pi-eye"
          style={{
            padding: "10px 5px",
            marginRight: "10px",
            cursor: "pointer",
          }}
          onClick={() => {
            handleViewData(rowData.id);
            GetHandelDetail(rowData?.rbzReferenceNumber, rowData.id);
            GetApplicationCount(rowData.id);
          }}
          onMouseEnter={(e) => {
            e.target.style.color = "var(--primary-color)";
          }}
          onMouseLeave={(e) => {
            e.target.style.color = "";
          }}
        ></i>

        <i
          className="pi pi-user-edit"
          style={{ cursor: "pointer" }}
          key={rowData.title}
          onClick={() => {
            handleClickEditModal(rowData.title);
            GetHandelDetail(rowData?.rbzReferenceNumber, rowData.id);
            GetRoleHandle(applicationstaus);
            // handleData();
            GetApplicationCount(rowData.id);
          }}
          onMouseEnter={(e) => {
            e.target.style.color = "var(--primary-color)";
          }}
          onMouseLeave={(e) => {
            e.target.style.color = "";
          }}
        ></i>
      </>
    );
  };

  const applicantName = (rowData) => {
    return (
      <>
        {rowData.applicantType === 1 || rowData.userTypeID === 1 ? (
          <span>
            <i className="bi bi-c-circle-fill text-primary"></i>
            &nbsp;&nbsp;{rowData.companyName}
          </span>
        ) : rowData.applicantType === 2 || rowData.userTypeID == 2 ? (
          <span>
            <i className="bi bi-person-circle"></i>
            &nbsp;&nbsp;{rowData.name}
          </span>
        ) : (
          ""
        )}
      </>
    );
  };

  const amountwithCurrency = (rowData) => {
    return (
      <span>
        {rowData.currencyCode}&nbsp;{rowData.amount?.toLocaleString()}
      </span>
    );
  };

  const submittedDate = (rowData) => {
    return (
      <span>
        {moment(rowData.applicationSubmittedDate).format("DD MMM YYYY")}
      </span>
    );
  };

  const receivedDate = (rowData) => {
    return (
      <span>
        {rowData.receivedDate
          ? moment(rowData.receivedDate).format("DD MMM YYYY")
          : "--"}
      </span>
    );
  };

  const header = renderHeader();

  const EditModalClose = () => {
    setshowEditForm(false);
    setnextlevelvalue("");
    setApplicationDetail({});
    setNoDataComment([]);
    setActiondata([]);
  };

  const handleFormClose = () => {
    setShowUpdateModal(false);
    setApplicationDetail();
    setNoDataComment([]);
    setActiondata([]);
  };

  const handleotherpopupOpen = () => setviewotherpopup(true);

  const handleotherpopupClose = () => setviewotherpopup(false);

  // ----- Start Code For Open Edit Popup
  const handleClickEditModal = () => {
    setshowEditForm(true);
  };
  // ----- End Code For Open Edit Popup

  // ----- Start Code For Geting Table List Data
  const handleData = async () => {
    setLoading(true);
    const DataExpDashboard = {
      UserID: useId.replace(/"/g, ""),
      BankID: bankId
    }
    await axios
      .post(APIURL + "ExportApplication/GetExportDashboardDataByUserID", DataExpDashboard)
      .then((res) => {
        if (res.data.responseCode === "200") {
          setLoading(false);
          setData(res.data.responseData);
          setexportdata(res.data.responseData);
          setTimeout(() => {
          }, 2500);
        } else {
          setData([]);
          setLoading(false);
          setexportdata([]);
        }
      })
      .catch((err)=>{
        console.log(err)
        const api = "ExportApplication/GetExportDashboardDataByUserID"
        ErrorlogData(DataExpDashboard ,err, api )
      });
  };
  // ----- End Code For Geting Table List Data

  const supervisorHangechangeBankuser = (e) => {
    const { value } = e.target;
    if (value == "") {
      setAssignUserID("");
      setSupervisorRoleId("");
    } else {
      const { userID, roleID } = JSON?.parse(value);
      setAssignUserID(userID);
      setSupervisorRoleId(roleID);
    }
  };

  const supervisorHangechangeRole = (e) => {
    const { name, value } = e.target;
    if (value == "90A" || value == "") {
      setAssignUserID("");
      setSupervisorRoleId("");
      setAsignUser([]);
    } else {
      const DatagetUser = {
        RoleID:
          value == "10" || value == "40" || value == "25" || value == "30"
            // ? parseInt(role) + 1
            ? roleID == 8 ? parseInt(role) + 1 : ""
            : value == "15" ||
              value == "5" ||
              value == "6" ||
              value == "7" ||
              value == "8" ||
              value == "9"
            ? 
              value
            : role,
        DepartmentID: "2",
        UserID: useId.replace(/"/g, ""),
      }
      axios
        .post(APIURL + "User/GetUsersByRoleID", DatagetUser)
        .then((res) => {
          if (res.data.responseCode == 200) {
            setAsignUser(res.data.responseData);
          } else {
            setSupervisorRoleId("");
          }
        })
        .catch((err) => {
          console.log(err);
          const api = "User/GetUsersByRoleID"
        ErrorlogData(DatagetUser ,err, api )
        });
    }
  };

  const supervisorHangechange = (e) => {
    const { name, value } = e.target;
    if (value == "") {
      setAssignUserID(null);
    } else {
      setAssignUserID(value);
    }
  };
  // ---- start API code for Comment

  // ----- Start Code For Open Poup
  const handleViewData = (id) => {
    setShowUpdateModal(true);
  };
  // ----- End Code For Open Poup

  // ----- Start Code For Geting Table Data
  const GetHandelDetail = async (rbzrefnumber, id) => {
    setshowdataloader(true);
    setApplicationDetail({});
    const DataGetRequestInfo = {
      RBZReferenceNumber: `${rbzrefnumber}`,
      ID: id,
    }
    await axios
      .post(APIURL + "ExportApplication/GetRequestInfoByApplicationID", DataGetRequestInfo)
      .then((res) => {
        if (res.data.responseCode === "200") {
          setApplicationDetail(res.data.responseData);
          setTimeout(() => {
            setshowdataloader(false);
          }, 2000);
        } else {
          setApplicationmessage(res.data.responseMessage);
        }
      })
      .catch((err) => {
        console.log(err);
        const api = "ExportApplication/GetRequestInfoByApplicationID"
        ErrorlogData(DataGetRequestInfo ,err, api )
      });

      const DataGetcommjson = {
        ApplicationID: id,
      }
    await axios
      .post(APIURL + "ExportApplication/GetCommentsInfoByRoleID",DataGetcommjson )
      .then((res) => {
        if (res.data.responseCode == 200) {
          setNoDataComment(res.data.responseData);
        } else {
          setNoDataComment([]);
        }
      })
      .catch((err) => {
        console.log(err);
        const api = "ExportApplication/GetCommentsInfoByRoleID"
        ErrorlogData(DataGetcommjson ,err, api )
      });

      const DataGetNew = {
        ID: id,
      }
    await axios
      .post(APIURL + "ExportApplication/GetNewComments", DataGetNew)
      .then((res) => {
        if (res.data.responseCode == 200) {
          setallcomment(res.data.responseData);
        } else {
          setallcomment([]);
        }
      })
      .catch((err) => {
        console.log(err);
        const api = "ExportApplication/GetNewComments"
        ErrorlogData(DataGetNew ,err, api )
      });

      const DataApplicationJson = {
        ID: id,
        RoleID: rollId
      }
    await axios
      .post(APIURL + "ExportApplication/GetApplicationHistory",DataApplicationJson )
      .then((res) => {
        if (res.data.responseCode == 200) {
          setTatHistory(res.data.responseData);
        } else {
          setTatHistory([]);
        }
      })
      .catch((err) => {
        console.log(err);
        const api = "ExportApplication/GetApplicationHistory"
        ErrorlogData(DataApplicationJson ,err, api )
      });

      const DataActionIdjson ={
        ID: id,
      }
    await axios
      .post(APIURL + "ExportApplication/GetApplicationActionsByApplicationID", DataActionIdjson)
      .then((res) => {
        if (res.data.responseCode == 200) {
          setActiondata(res.data.responseData);
        } else {
          setActiondata([]);
        }
      })
      .catch((err) => {
        console.log(err);
        const api = "ExportApplication/GetApplicationActionsByApplicationID"
        ErrorlogData(DataActionIdjson ,err, api )
      });
  };

  const GetApplicationCount = async (id) => {
    const DataCountByAppId = {
      ApplicationID: id,
    }
    await axios
      .post(APIURL + "ExportApplication/CountByApplicationID", DataCountByAppId)
      .then((res) => {
        if (res.data.responseCode == 200) {
          setresponceCount(res.data.responseData);
        } else {
          setresponceCount({});
        }
      })
      .catch((err) => {
        console.log(err);
        const api = "ExportApplication/CountByApplicationID"
        ErrorlogData(DataCountByAppId ,err, api )
      });
  };

  // ----- End Code For Geting Table Data

  // ----- Start Code For Search Table Data
  const filteredData = exportdata?.filter(
    (item) =>
      item?.rbzReferenceNumber
        ?.toLowerCase()
        .includes(searchText?.toLowerCase()) ||
      item?.companyName?.toString().includes(searchText) ||
      item?.supervisorName?.toString().includes(searchText) ||
      item?.amount?.toString().includes(searchText) ||
      item?.name?.toString().includes(searchText) ||
      moment(item?.applicationSubmittedDate)
        .format("DD:MM:YYYY")
        ?.toString()
        .includes(searchText) ||
      item?.applicationType?.toString().includes(searchText) ||
      item?.currencyName?.toString().includes(searchText) ||
      item?.status?.toString().includes(searchText)
  );
  // ----- End Code For Search Table Data

  useEffect(() => {
    setapplicationstaus(
      applicationDetail?.analystRecommendation
        ? applicationDetail?.analystRecommendation
        : "0"
    );
  }, [applicationDetail]);

  const GetRoleHandle = async (id) => {
    setUserrole([]);
    const Datagetrolejson = {
      RoleID: role,
      Status: `${id}`,
      DepartmentID: "2",
    }
    await axios
      .post(APIURL + "Master/GetRoles",Datagetrolejson )
      .then((res) => {
        if (res.data.responseCode == 200) {
          setUserrole(res.data.responseData);
        } else {
          setUserrole([]);
        }
      })
      .catch((err) => {
        console.log(err);
        const api = "Master/GetRoles"
        ErrorlogData(Datagetrolejson ,err, api )
      });
  };

  useEffect(() => {
    handleData();
  }, []);

  // OLD
  const [showOldModal, setShowOldModal] = useState(false);
  const [oldApplicationDetail, setOldApplicationDetail] = useState({});
  const [oldNoDataComment, setOldNoDataComment] = useState([]);
  const [oldAllcomment, setOldAllcomment] = useState([]);
  const [oldTatHistory, setOldTatHistory] = useState([]);
  const [oldActiondata, setOldActiondata] = useState([]);
  const [oldResponceCount, setOldResponceCount] = useState([]);
  const [showOldDataLoader, setShowOldDataLoader] = useState(false);

  const handleOldClose = () => setShowOldModal(false);

  const handleOldViewData = (id) => {
    setShowOldModal(true);
  };

  const GetOldHandelDetail = async (id) => {
    setShowOldDataLoader(true);
    const DatareqinfoIdJson = {
      ID: id,
    }
    await axios
      .post(APIURL + "ExportApplication/GetRequestInfoByApplicationID", DatareqinfoIdJson)
      .then((res) => {
        if (res.data.responseCode === "200") {
          setOldApplicationDetail(res.data.responseData);
          setTimeout(() => {
            setShowOldDataLoader(false);
          }, 2000);
        }
      })
      .catch((err) => {
        console.log(err);
        const api = "ExportApplication/GetRequestInfoByApplicationID"
        ErrorlogData(DatareqinfoIdJson ,err, api )
      });

      const Datacommntjson ={
        ApplicationID: id,
      }
    await axios
      .post(APIURL + "ExportApplication/GetCommentsInfoByRoleID", Datacommntjson)
      .then((res) => {
        if (res.data.responseCode == 200) {
          setOldNoDataComment(res.data.responseData);
        } else {
          setOldNoDataComment([]);
        }
      })
      .catch((err) => {
        console.log(err);
        const api = "ExportApplication/GetCommentsInfoByRoleID"
        ErrorlogData(Datacommntjson ,err, api )
      });

      const Datajsonnewcomm= {
        ID: id,
      }
    await axios
      .post(APIURL + "ExportApplication/GetNewComments", Datajsonnewcomm)
      .then((res) => {
        if (res.data.responseCode == 200) {
          setOldAllcomment(res.data.responseData);
        } else {
          setOldAllcomment([]);
        }
      })
      .catch((err) => {
        console.log(err);
        const api = "ExportApplication/GetNewComments"
        ErrorlogData(Datajsonnewcomm ,err, api )
      });

      const DataJsonhistory = {
        ID: id,
        RoleID:roleID
      }
    await axios
      .post(APIURL + "ExportApplication/GetApplicationHistory", DataJsonhistory)
      .then((res) => {
        if (res.data.responseCode == 200) {
          setOldTatHistory(res.data.responseData);
        } else {
          setOldTatHistory([]);
        }
      })
      .catch((err) => {
        console.log(err);
        const api = "ExportApplication/GetApplicationHistory"
        ErrorlogData(DataJsonhistory ,err, api )
      });

      const DatajsonAppid= {
        ID: id,
      }
    await axios
      .post(APIURL + "ExportApplication/GetApplicationActionsByApplicationID", DatajsonAppid )
      .then((res) => {
        if (res.data.responseCode == 200) {
          setOldActiondata(res.data.responseData);
        } else {
          setOldActiondata([]);
        }
      })
      .catch((err) => {
        console.log(err);
        const api = "ExportApplication/GetApplicationActionsByApplicationID"
        ErrorlogData(DatajsonAppid ,err, api )
      });
  };

  const GetOldApplicationCount = async (id) => {
    const DatajsonCountAppID = {
      ApplicationID: id,
    }
    await axios
      .post(APIURL + "ExportApplication/CountByApplicationID", DatajsonCountAppID)
      .then((res) => {
        if (res.data.responseCode == 200) {
          setOldResponceCount(res.data.responseData);
        } else {
          setOldResponceCount({});
        }
      })
      .catch((err) => {
        console.log(err);
        const api = "ExportApplication/CountByApplicationID"
        ErrorlogData(DatajsonCountAppID ,err, api )
      });
  };

  //Fetch Referred to Other Department data
  const [RODLoader, setRODLoader] = useState(false);
  const [referredData, setReferredData] = useState({});
  const GetReferredData = async (id) => {
    try {
      setRODLoader(true);
      const DatajsonrefferdId ={
        ID: id,
      }
      await axios
        .post(APIURL + "ReferredApplication/GetReferredApplicationDataByID", DatajsonrefferdId)
        .then((res) => {
          if (res.data.responseCode == 200) {
            setReferredData(res.data.responseData);
            setRODLoader(false);
          } else {
            setReferredData({});
            setRODLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setRODLoader(false);
          // setreferredDataTrue(false);
          const api = "ReferredApplication/GetReferredApplicationDataByID"
          ErrorlogData(DatajsonrefferdId ,err, api )
        });
    } catch (error) {
      console.log("GetReferredImportApplicationByID error - ", error); 
    }
  };

  return (
    <>
      {loading == true ? (
        <label className="outerloader2">
          <span className="loader"></span>
          <span className="loaderwait">Please Wait...</span>
        </label>
      ) : (
        <div>
          <DataTable
            value={data}
            scrollable
            scrollHeight="650px"
            className={roleID >= 5 || roleID == 3 ? "mt-1" : "mt-1 "}
            selection={selectedAppliation}
            onSelectionChange={(e) => setSelectedAppliation(e.value)}
            paginator={data.length > 10 ? true : false}
            filters={filters}
            selectionMode="checkbox"
            paginatorPosition={"both"}
            paginatorLeft
            rows={10}
            dataKey="id"
            rowHover
            rowsPerPageOptions={[10, 50, 100]}
            globalFilterFields={[
              "rbzReferenceNumber",
              "name",
              "companyName",
              "applicationType",
              "amount",
              "statusName",
            ]}
            emptyMessage="No Data found."
            header={header}
          >            
            <Column
              field="rbzReferenceNumber"
              header="RBZ Reference Number"
              sortable
              style={{ width: "220px" }}
            ></Column>
            <Column
              field="companyName"
              header="Applicant Name"
              sortable
              style={{ width: "220px" }}
              body={applicantName}
            ></Column>
            <Column
              field="applicationSubmittedDate"
              header="Submitted Date"
              sortable
              style={{ width: "140px" }}
              body={submittedDate}
            ></Column>
            <Column
                field="receivedDate"
                header="Received Date"
                sortable
                style={
                  rollId == 2 || rollId == 3
                    ? { display: "none" }
                    : { width: "140px" }
                }
                body={receivedDate}
              ></Column>
            <Column
              field="applicationType"
              header="Application Type"
              sortable
              style={{ width: "200px" }}
            ></Column>
            <Column
              field="amount"
              header="Amount"
              sortable
              body={amountwithCurrency}
              style={{ width: "150px" }}
            ></Column>
            <Column
              field="statusName"
              header="Status"
              sortable
              style={{ width: "200px" }}
            ></Column>
            <Column
              field="assignedToRoleName"
              header="Role"
              sortable
              style={{ width: "200px" }}
            ></Column>
            <Column
              field=""
              header="Action"
              style={{ width: "200px" }}
              frozen
              alignFrozen="right"
              body={action}
            ></Column>
          </DataTable>
        </div>
      )}
      <Modal
        show={showUpdateModal}
        onHide={handleFormClose}
        backdrop="static"
        className="max-width-600"
      >
        <div className="application-box">
          <div className="login_inner">
            <div className="login_form ">
              <h5>
                <Modal.Header closeButton className="p-0">
                  <Modal.Title>
                    View Export Request --{" "}
                    <big>{applicationDetail?.rbzReferenceNumber}</big>
                  </Modal.Title>
                </Modal.Header>
              </h5>
            </div>
            <div className="login_form_panel">
              <Modal.Body className="p-0">
                <ExportAllRecordsDashboardViewDetails
                  applicationDetail={applicationDetail}
                  handleFormClose={handleFormClose}
                  allcomment={allcomment}
                  tatHistory={tatHistory}
                  Actiondata={Actiondata}
                  noDataComment={noDataComment}
                  showdataLoader={showdataLoader}
                  responceCount={responceCount}
                />
              </Modal.Body>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        show={showOldModal}
        onHide={handleOldClose}
        backdrop="static"
        className="max-width-600 oldModal-full"
      >
        <div className="application-box">
          <div className="login_inner">
            <div className="login_form ">
              <h5>
                <Modal.Header closeButton className="p-0">
                  <Modal.Title>
                    View Old Export Request --{" "}
                    <big>{oldApplicationDetail?.rbzReferenceNumber}</big>
                  </Modal.Title>
                </Modal.Header>
              </h5>
            </div>
            <div className="login_form_panel">
              <Modal.Body className="p-0">
                <ExportDashboardViewDetails
                  applicationDetail={oldApplicationDetail}
                  handleFormClose={handleOldClose}
                  allcomment={oldAllcomment}
                  tatHistory={oldTatHistory}
                  Actiondata={oldActiondata}
                  noDataComment={oldNoDataComment}
                  showdataLoader={showOldDataLoader}
                  responceCount={oldResponceCount}
                />
              </Modal.Body>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        show={showEditForm}
        onHide={EditModalClose}
        backdrop="static"
        className="max-width-600"
      >
        <div className="application-box">
          <div className="login_inner">
            <div className="login_form ">
              <h5>
                <Modal.Header closeButton className="p-0">
                  <Modal.Title style={{ width: "100%" }}>
                    <div className="row">
                      <div
                        className={
                          applicationDetail?.parentApplicationID == 0 &&
                          applicationDetail?.referredApplicationID == 0
                            ? "col-md-12"
                            : "col-md-6"
                        }
                        style={{ alignItems: "center" }}
                      >
                        Edit Export Request --{" "}
                        <big>
                          {applicationDetail?.rbzReferenceNumber
                            ? applicationDetail.rbzReferenceNumber
                            : ""}
                        </big>
                      </div>
                      <div
                        className={
                          applicationDetail &&
                          applicationDetail?.parentApplicationID == 0
                            ? "d-none"
                            : "col-md-6 text-center"
                        }
                      >
                        <button
                          className={
                            applicationDetail?.parentApplicationID
                              ? "btn btn-light viewcopybtn"
                              : "d-none"
                          }
                          onClick={() => {
                            handleOldViewData(
                              applicationDetail?.parentApplicationID
                            );
                            GetOldHandelDetail(
                              applicationDetail?.parentApplicationID
                            );
                            GetOldApplicationCount(
                              applicationDetail?.parentApplicationID
                            );
                          }}
                        >
                          View Old Application
                        </button>
                      </div>
                      <div
                        className={
                          applicationDetail &&
                          applicationDetail?.referredApplicationID == 0
                            ? "d-none"
                            : "col-md-6 text-center"
                        }
                      >
                        <button
                          className={
                            applicationDetail?.referredApplicationID
                              ? "btn btn-light viewcopybtn"
                              : "d-none"
                          }
                          onClick={() => {
                            GetReferredData(
                              applicationDetail?.referredApplicationID
                            );
                            handleotherpopupOpen();
                          }}
                        >
                          View Other Department Response
                        </button>
                      </div>
                    </div>
                  </Modal.Title>
                </Modal.Header>
              </h5>
            </div>
            <div className="login_form_panel">
              <Modal.Body className="p-0">
                <ExportallrecordDashboardEditDetails
                  applicationDetail={applicationDetail}
                  setApplicationDetail={setApplicationDetail}
                  EditModalClose={EditModalClose}
                  setexportdata={setexportdata}
                  handleData={handleData}
                  showdataLoader={showdataLoader}
                  allcomment={allcomment}
                  GetRoleHandle={GetRoleHandle}
                  setapplicationstaus={setapplicationstaus}
                  applicationstaus={applicationstaus}
                  setnextlevelvalue={setnextlevelvalue}
                  nextlevelvalue={nextlevelvalue}
                  asignUser={asignUser}
                  userRole={userRole}
                  responceCount={responceCount}
                  setAsignUser={setAsignUser}
                  supervisorHangechange={supervisorHangechange}
                  supervisorHangechangeBankuser={supervisorHangechangeBankuser}
                  tatHistory={tatHistory}
                  AssignUserID={AssignUserID}
                  setAssignUserID={setAssignUserID}
                  Actiondata={Actiondata}
                  SupervisorRoleId={SupervisorRoleId}
                  supervisorHangechangeRole={supervisorHangechangeRole}
                  setSupervisorRoleId={setSupervisorRoleId}
                  noDataComment={noDataComment}
                  ExporttabDepId={tabDepId}
                />
              </Modal.Body>
            </div>
          </div>
        </div>
      </Modal>  

      <Modal
        show={viewotherpopup}
        onHide={handleotherpopupClose}
        backdrop="static"
        className="max-width-600"
      >
        <div className="application-box">
          <div className="login_inner">
            <div className="login_form ">
              <h5>
                <Modal.Header closeButton className="p-0">
                  <Modal.Title>
                    View Other Department Response --{" "}
                    <big>{applicationDetail?.rbzReferenceNumber}</big>
                  </Modal.Title>
                </Modal.Header>
              </h5>
            </div>
            <div className="login_form_panel">
              <Modal.Body className="p-0">
                <div className="view-bx">
                  <h3 className="deparment-headertooltip">
                    <span>Response</span>{" "}
                  </h3>

                  {RODLoader === true ? (
                    <label className="outerloader2">
                      <span className="loader"></span>
                      <span className="loaderwait">Please Wait...</span>
                    </label>
                  ) : (
                    <>
                      <div className="toolinner">
                        <label>Department</label>{" "}
                        <p>{referredData?.departmentName}</p>
                      </div>
                      <div className="toolinner">
                        <label>Recommendation</label>{" "}
                        <p
                          dangerouslySetInnerHTML={{
                            __html: referredData.description
                              ? referredData.description
                              : "N/A",
                          }}
                        />
                      </div>
                      <div className="toolinner">
                        <label>Notes</label> <p>{referredData?.notes}</p>
                      </div>
                      <div className="toolinner">
                        <label>Comments</label> <p>{referredData?.comment}</p>
                      </div>
                      <div className="toolinner">
                        <label>Response Date</label>{" "}
                        <p>
                          {moment(referredData?.createdDate).format(
                            "DD/MMM/YYYY hh:mm:ss A"
                          )}
                        </p>
                      </div>

                      <h3 className="deparment-headertooltip">
                        <span>Shared File </span>
                      </h3>

                      {referredData?.sharedFilesData?.length ? (
                        referredData?.sharedFilesData?.map((items, index) => {
                          return (
                            <div className="attachemt_form-bx" key={items.id}>
                              <label
                                style={{
                                  background: "#d9edf7",
                                  padding: "10px",
                                }}
                              >
                                {/* {items.filename} */}
                                {items?.fileName
                                  ? items?.fileName
                                  : `FileUpload ${index}`}
                              </label>
                              <span className="filename">
                                <Link
                                  to={items?.filePath}
                                  target="_blank"
                                  className="viewbtn"
                                >
                                  View File
                                </Link>
                              </span>
                            </div>
                          );
                        })
                      ) : (
                        <span
                          style={{
                            fontSize: "14px",
                            display: "block",
                            marginLeft: "10px",
                          }}
                        >
                          No File Found
                        </span>
                      )}
                    </>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer className="mt-0">
                <div className="form-footer mt-0 justify-content-end">
                  <button
                    type="submit"
                    onClick={() => handleotherpopupClose()}
                    className="register"
                  >
                    <span className="d-flex align-items-center justify-content-center">
                      Close
                    </span>
                  </button>
                </div>
              </Modal.Footer>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ExportAllRecordsTable;
