import React from "react";
import { Link,useNavigate } from "react-router-dom";
import DashboardLayout from "../components/DashboardLayout";
import FINAllRequetsTable from "../tables/FINAllRequetsTable";
import { Storage } from "../login/Storagesetting";
import FINAllRequetsIndividualTable from "../tables/FINAllRequetsIndividualTable";
import { Helmet } from "react-helmet";

const FINVSubmittedRequests = () => {
  const rollId = Storage.getItem("roleIDs");
  const navigate = useNavigate();
  const handleAdvanceSearch = () => {
    navigate("/AdvanceSearchFIN");
  }
    const menuname = sessionStorage.getItem("menuname");
    const submenuname = sessionStorage.getItem("submenuname");
    return (
      <>
        <Helmet>
          <title>
            {menuname} {submenuname}
          </title>
        </Helmet>
        <DashboardLayout>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="">Foreign Investments</Link>
              </li>
              <li className="breadcrumb-item active">All Requests</li>
            </ol>
          </nav>
  
          <section className="section dashboard">
            <div className="row">
              <div className="col-md-12">
                <div className="datatable">
                  <h4 className="section_top_heading">All REQUESTS
                  <button
                    type="button"
                    className="adSearchButton"
                    onClick={() => handleAdvanceSearch()}
                  >
                    Advanced Search
                  </button>
                  </h4>
                  {rollId == 10 || rollId == 11 ? <FINAllRequetsIndividualTable/> : <FINAllRequetsTable />}
                </div>
              </div>
            </div>
          </section>
        </DashboardLayout>
      </>
    );
}

export default FINVSubmittedRequests
