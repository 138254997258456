import React from 'react'
import { Helmet } from 'react-helmet'
import AdminDashboardLayout from '../components/AdminDashboardLayout'
import SubApplicationTypeTable from '../tables/SubApplicationTypeTable'
import { Storage } from '../../login/Storagesetting'
import DashboardLayout from '../../components/DashboardLayout'

const ApplicationType = () => {
    const roleId = Storage.getItem("roleIDs")

    const Layout = roleId === '1' ? AdminDashboardLayout : DashboardLayout;

    return (
        <>
            <Helmet><title>Application Sub Category</title></Helmet>
            <Layout >
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li className="breadcrumb-item active">Application Sub Category</li>
                    </ol>
                </nav>

                <section className="section dashboard adminDashboard">
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='datatable'>
                                <h4 className='section_top_heading'>Application Sub Category</h4>
                                {/* application type tableData */}
                                <SubApplicationTypeTable />
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default ApplicationType
