import axios from "axios";
import React, { useEffect, useState } from "react";
import { APIURL } from "../constant";
import { Storage } from "../login/Storagesetting";
import ErrorsLogApi from "./ErrorslogApi";

const ExportformDynamicField = () => {
  const RoleID = Storage.getItem("roleIDs");
  const LoginToken = Storage.getItem("loginToken");
  const UserID = Storage.getItem("userID");
  const BankID = Storage.getItem("bankID");
  const {ErrorlogData} = ErrorsLogApi();


  const [countries, setcountries] = useState([]);
  const [currency, setCurrecny] = useState([]);
  const [companies, setompanies] = useState([]);
  const [applicantName, setapplicantName] = useState([]);

  const [applicantTypes, setapplicantTypes] = useState([]);

  const [GovernmentAgencies, setGovernmentAgencies] = useState([]);

  const [sectorData, setsectordata] = useState([]);

  const [masterBank, setMasterBank] = useState([]);

  const [Supervisors, setSupervisors] = useState([]);

  const [ImpSupervisors, setImpSupervisors] = useState([]);

  

  const Getcurrecy = async () => {

    try {
      const response = await axios.post(APIURL + "Master/GetApplicantTypes");
      if (response.data.responseCode === "200") {
         setapplicantTypes(response.data.responseData);
      } else {
        setapplicantTypes("");
      }
    } catch (error) {
      console.log(error);
      const api = "Master/GetApplicantTypes"
      ErrorlogData('' ,error, api )

    }

    await axios
      .post(APIURL + "Master/GetCurrencyData", {
        RoleID: RoleID,
        LoginToken: LoginToken,
        UserID: UserID,
      })
      .then((res) => {
        setCurrecny(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
        const datajnson = {
          RoleID: RoleID,
          LoginToken: LoginToken,
          UserID: UserID,
        }
        ErrorlogData(datajnson ,err, "Master/GetCurrencyData" )
        console.log(err);
      });

    await axios
      .post(APIURL + "Master/GetCompanies")
      .then((res) => {
        setompanies(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });

    await axios
      .post(APIURL + "User/GetIndividualUsers")
      .then((res) => {
        if (res.data.responseCode === "200") {
          setapplicantName(res.data.responseData);
        } else {
          console.log(res.data.responseMessage);
        }
      })
      .catch((err) => {
        console.log(err);
        ErrorlogData('' ,err, "User/GetIndividualUsers" )
      });

    await axios
      .post(APIURL + "Master/GetGovernmentAgencies")
      .then((res) => {
        if (res.data.responseCode === "200") {
          setGovernmentAgencies(res.data.responseData);
        } else {
          console.log(res.data.responseMessage);
        }
      })
      .catch((err) => {
        console.log(err); 
        ErrorlogData('' ,err, "Master/GetGovernmentAgencies" )
      });

    await axios
      .post(APIURL + "Master/GetSectorData")
      .then((res) => {
        if (res.data.responseCode === "200") {
          setsectordata(res.data.responseData);
        } else {
          console.log(res.data.responseMessage);
        }
      })
      .catch((err) => {
        console.log(err);  
        ErrorlogData('' ,err, "Master/GetSectorData" )
      });

    await axios
      .post(APIURL + "Master/GetMasterBank")
      .then((res) => {
        if (res.data.responseCode === "200") {
          setMasterBank(res.data.responseData);
        } else {
          console.log(res.data.responseMessage);
        }
      })
      .catch((err) => {
        console.log(err);
        ErrorlogData('' ,err, "Master/GetMasterBank" )
      });

   
 
    await axios
      .post(APIURL + "User/GetSupervisors", {
        BankID: BankID,
        UserID: UserID,
        DepartmentID:"2",
        RoleID: RoleID,
      })
      .then((res) => {
        if (res.data.responseCode === "200") {
          setSupervisors(res.data.responseData);
        } else {
          console.log(res.data.responseMessage);
          setSupervisors([]);
        }
      })
      .catch((err) => {
        console.log(err);
        const Datajsn = {
          BankID: BankID,
          UserID: UserID,
          DepartmentID:"2",
          RoleID: RoleID,
        }
        ErrorlogData(Datajsn ,err, "User/GetSupervisors" )
      });

      await axios
      .post(APIURL + "User/GetSupervisors", {
        BankID: BankID,
        UserID: UserID,
        DepartmentID:"3",
        RoleID: RoleID,
      })
      .then((res) => {
        if (res.data.responseCode === "200") {
          setImpSupervisors(res.data.responseData);
        } else {
          // console.log(res.data.responseMessage);
          setImpSupervisors([]);
        }
      })
      .catch((err) => {
        console.log(err);
        const datajsn = {
          BankID: BankID,
          UserID: UserID,
          DepartmentID:"3",
          RoleID: RoleID,
        }
        ErrorlogData(datajsn ,err, "User/GetSupervisors" )
      });

    await axios
      .post(APIURL + "Admin/GetAllCountry")
      .then((res) => {
        if (res.data.responseCode === "200") {
          setcountries(res.data.responseData);
        } else {
          console.log(res.data.responseMessage);
          setcountries([]);
        }
      })
      .catch((err) => {
        console.log(err);
        ErrorlogData('' ,err, "Admin/GetAllCountry" )
      });
  };

  useEffect(() => {
    Getcurrecy();
  }, []);

  return {
    currency,
    companies,
    GovernmentAgencies,
    applicantTypes,
    sectorData,
    masterBank,
    Supervisors,
    ImpSupervisors,
    applicantName,
    countries,
  };
};

export default ExportformDynamicField;
