import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import DashboardLayout from "../components/DashboardLayout";
import ExportNewRequestForm from "../components/ExportNewRequestForm";
import ExportCircularsCreateForm from '../components/ExportCircularsCreateForm'
import { Link } from "react-router-dom";
import { APIURL } from "../constant";
import axios from "axios";
import ErrorsLogApi from "../components/ErrorslogApi";
import { Storage } from "../login/Storagesetting";

const CircularNewRequest = () => {
  const menuname = sessionStorage.getItem("menuname");
  const submenuname = sessionStorage.getItem("submenuname");

  const userID = Storage.getItem("userID");
  const { ErrorlogData } = ErrorsLogApi();

  const [imageSrc, setimageSrc]=useState('')

  useEffect(() => {
    const getProfileData = async () => {
      const DataReferred = {
        UserID: userID.replace(/"/g, ""),
      }
      try {
        const res = await axios.post(APIURL + "User/GetUserDetailsByUserID", DataReferred);
        console.log("res", res)
        if (res.data.responseCode == "200") { 
          setimageSrc( res.data.responseData.signImageURL)
  
        }
      } catch (err) {
        const api = "User/GetUserDetailsByUserID"
        ErrorlogData(DataReferred, err, api)
        console.log("Error -", err);
      }
    };
    getProfileData()
  }, [])

  return (
    <>
      <Helmet>
        <title>
          {menuname ? menuname : "Export"}{" "}
          {submenuname ? submenuname : "Dashboard"}
        </title>{" "}
      </Helmet>
      <DashboardLayout>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/BankADLADashboard">Circulars</Link>
            </li>
            <li className="breadcrumb-item active">New Circular</li>
          </ol>
        </nav>

        <section className="section dashboard">
          <div className="row">
            <div className="col-md-12">
              <div className="datatable">
                <h4 className="section_top_heading">NEW CIRCULAR</h4>

                <h5 className="section_top_subheading">Circular Info</h5>

                <ExportCircularsCreateForm imageSrc={imageSrc} />
              </div>
            </div>
          </div>
        </section>
      </DashboardLayout>
    </>
  );
};

export default CircularNewRequest;
