import axios from "axios";
import ErrorsLogApi from "../components/ErrorslogApi";
import { APIURL } from "../constant";
import { Storage } from "../login/Storagesetting";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";


const Menubar = () => {
  const {ErrorlogData} = ErrorsLogApi();
  const navigate = useNavigate();

  const bankId = Storage.getItem("bankID")

  const getMenudata = async (roleIDs, LoginToken, UserID, bankId) => {
    const DataReferred = {
      UserID: UserID?.replace(/"/g, ""),
      RoleID: roleIDs,
      BankID: bankId
    }
    await axios
      .post(APIURL + "User/MenuCount", DataReferred)
      .then((res) => {
        if (res.data.responseCode == 200) {
          Storage.removeItem("menucounter")
          Storage.setItem("menucounter", JSON.stringify(res.data.responseData))
          const MenuLoadAuto = () => {
            const x = 10000;
            const y = 100;
            const event = new MouseEvent("mousemove", {
              clientX: x,
              clientY: y,
            });
            // Dispatch the event
            document.dispatchEvent(event);
          };
          const timer = setTimeout(MenuLoadAuto, 1000);
          return () => clearTimeout(timer);
        }
      })
      .catch((err) => {
        const api = "User/MenuCount"
        ErrorlogData(DataReferred, err, api)
        console.log("err", err)
      })

    const DataReferred1 = {
      RoleID: roleIDs,
      LoginToken: LoginToken,
      UserID: UserID,
    }
    try {
      const response = await axios.post(APIURL + "User/GetMenuList", DataReferred1);
      if (response.data.responseCode === "200") {
        if (roleIDs != 1) {
          sessionStorage.setItem("menuname", response.data.responseData[0].menuName);
          if (response.data.responseData[0].menuName === "Home") {
            navigate("/AllDashboard");
          } else {
            navigate("/" + response.data.responseData[0].subMenu[0].subMenuURL);
            sessionStorage.setItem("submenuname", "Dashboard");
            sessionStorage.setItem("menuname", response.data.responseData[0].menuName);
          }
        } else {
          navigate('/PendingUser')
        }
        Storage.setItem("menuitem", JSON.stringify(response.data.responseData));
      } else {
        toast.warning(response.data.responseCode)
      }
    } catch (err) {
      const api = "User/GetMenuList"
      ErrorlogData(DataReferred1, err, api)
      console.log(err);
    }
  };

  const menuItemlocal = Storage.getItem("menuitem");

  const menucounts = Storage.getItem("menucounter")

  return {
    getMenudata,
    menuItemlocal,
    menucounts
    // menuCount,
  };
};

export default Menubar;
