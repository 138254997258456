import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DashboardLayout from "../components/DashboardLayout";
import ImportDashboardTable from "../tables/ImportDashboardTable";
import { Helmet } from "react-helmet";
import axios from "axios";
import { APIURL } from "../constant";
import { Storage } from "../login/Storagesetting";
import ErrorsLogApi from "../components/ErrorslogApi";

const ImportDashboard = () => {
  const { ErrorlogData } = ErrorsLogApi();
  const navigate = useNavigate();
  const handleAdvanceSearch = () => {
    navigate("/AdvanceSearchImport");
  };
  const [data, setData] = useState([]);

  const RoleId = Storage.getItem("roleIDs");
  const useId = Storage.getItem("userID");

  // ----- Start Code For Geting Table List Data
  const handleData = async () => {
    const DataReferred = {
      DepartmentID: "3",
      UserID: useId.replace(/"/g, ""),
      RoleID: RoleId,
    }
    await axios
      .post(APIURL + "ImportApplication/GetActingDashboardDataImport", DataReferred)
      .then((res) => {
        if (res.data.responseCode === "200") {
          setData(res.data.responseData);
        } else {
          setData([]);
        }
      }).catch((err) => {
        const api = "ImportApplication/GetActingDashboardDataImport"
        ErrorlogData(DataReferred, err, api)
      })
  };

  useEffect(() => {
    handleData();
  }, []);

  const clickhandlerole = () => {
    sessionStorage.setItem("submenuname", "Acting Role");
  };


  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <DashboardLayout>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="">Imports</Link>
            </li>
            <li className="breadcrumb-item active">Dashboard</li>
          </ol>
        </nav>

        <section className="section dashboard">
          <div className="row">
            <div className="col-md-12">
              <div className="datatable">
                <h4 className="section_top_heading">SUBMITTED REQUESTS


                  <div style={{ display: "flex", columnGap: "10px" }}>
                    {(RoleId == "6" || RoleId == "7" || RoleId == "8") &&
                      data?.length ? (
                      <Link
                        to="/ImpActingRoleDashboard"
                        className="act_btn-dashboard"
                        onClick={clickhandlerole}
                      >
                        Acting Role <span>{data?.length}</span>
                      </Link>
                    ) : (
                      ""
                    )}

                    <button
                      type="button"
                      className="adSearchButton"
                      onClick={() => handleAdvanceSearch()}
                    >
                      Advanced Search
                    </button>
                  </div>
                </h4>

                <ImportDashboardTable />
              </div>
            </div>
          </div>
        </section>
      </DashboardLayout>
    </>
  );
};

export default ImportDashboard;
