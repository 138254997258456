import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { APIURL } from "../constant";
import { Storage } from "../login/Storagesetting";
import Modal from "react-bootstrap/Modal";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterService } from "primereact/api";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { InputText } from "primereact/inputtext";
import "primeicons/primeicons.css";
import ImportDashboardViewDetails from "../components/ImportDashboardViewDetails";
import ImportDashboardRenewEditDetails from "../components/ImportDashboardRenewEditDetails";
import ErrorsLogApi from "../components/ErrorslogApi";

const ImportPendingRetunrsTable = () => {
  const useId = Storage.getItem("userID");
  const rollId = Storage.getItem("roleIDs");
  const bankID = Storage.getItem("bankID");
  const menuname = Storage.getItem("menuname");
  const submenuname = Storage.getItem("submenuname");
  const {ErrorlogData} = ErrorsLogApi();

  const [ImportPendingReturns, setImportPendingReturns] = useState([]);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [applicationDetail, setApplicationDetail] = useState({});
  const [applicationmessage, setApplicationmessage] = useState("");
  const [searchText, setSearchText] = useState("");
  const [tatHistory, setTatHistory] = useState([]);
  const [allcomment, setallcomment] = useState([]);
  const [Actiondata, setActiondata] = useState([]);
  const [noDataComment, setNoDataComment] = useState([]);
  const [showEditForm, setshowEditForm] = useState(false);
  const [nextlevelvalue, setnextlevelvalue] = useState("");
  const [responceCount, setresponceCount] = useState([]);
  const [exportdata, setexportdata] = useState([]);
  const [showdataLoader, setshowdataloader] = useState(false);
  const [userRole, setUserrole] = useState([]);
  const [AssignUserID, setAssignUserID] = useState("");
  const [SupervisorRoleId, setSupervisorRoleId] = useState("");
  const [loading, setLoading] = useState(false);

  const [asignUser, setAsignUser] = useState([]);
  const [IsDeferred, setIsDeferred] = useState("0");
  const [filterDays, setFilterDays] = useState(30);
  const [applicationstaus, setapplicationstaus] = useState(
    applicationDetail?.applicationStatus
      ? `${applicationDetail?.applicationStatus}`
      : "0"
  );

  const handleFormClose = () => setShowUpdateModal(false);

  FilterService.register("custom_activity", (value, filters) => {
    const [from, to] = filters ?? [null, null];
    if (from === null && to === null) return true;
    if (from !== null && to === null) return from <= value;
    if (from === null && to !== null) return value <= to;
    return from <= value && value <= to;
  });

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    companyName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    bankName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    tinNumber: { value: null, matchMode: FilterMatchMode.IN },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </span>
      </div>
    );
  };

  const handleClickEditModal = () => {
    setshowEditForm(true);
  };

  const EditModalClose = () => {
    setshowEditForm(false);
    setnextlevelvalue("");
  };

  const action = (rowData) => {
    return (
      <>
        <div className="d-flex align-items-center">
          <i
            className="pi pi-eye"
            style={{ padding: "", cursor: "pointer" }}
            onClick={() => {
              handleViewData(rowData.id);
              GetHandelDetail(rowData?.rbzReferenceNumber, rowData.id);
              GetApplicationCount(rowData.id);
            }}
            onMouseEnter={(e) => {
              e.target.style.color = "var(--primary-color)";
            }}
            onMouseLeave={(e) => {
              e.target.style.color = "";
            }}
          ></i>
          &nbsp; &nbsp; &nbsp;
          {rollId === "2" && (
            <button
              className="btn viewbtn_file"
              style={{ fontSize: "13px", textDecoration: "none" }}
              key={rowData.title}
              onClick={() => {
                handleClickEditModal(rowData.title);
                GetHandelDetail(rowData?.rbzReferenceNumber, rowData.id);
                GetRoleHandle(applicationstaus);
                GetApplicationCount(rowData.id);
              }}
            >
              Submit
            </button>
          )}
        </div>
      </>
    );
  };

  const applicantName = (rowData) => {
    return (
      <>
        {rowData.applicantType === 1 ? (
          <span>
            <i className="bi bi-c-circle-fill text-primary"></i>
            &nbsp;&nbsp;{rowData.companyName}
          </span>
        ) : rowData.applicantType === 2 ? (
          <span>
            <i className="bi bi-person-circle"></i>
            &nbsp;&nbsp;{rowData.name}
          </span>
        ) : (
          ""
        )}
      </>
    );
  };

  const submittedDate = (rowData) => {
    return (
      <span>
        {moment(rowData.applicationSubmittedDate).format("DD MMM YYYY")}
      </span>
    );
  };

  const receivedDate = (rowData) => {
    return (
      <span>
        {rowData.receivedDate
          ? moment(rowData.receivedDate).format("DD MMM YYYY")
          : "--"}
      </span>
    );
  };

  const amountwithCurrency = (rowData) => {
    return (
      <span>
        {rowData.currencyCode}&nbsp;{rowData.amount?.toLocaleString()}
      </span>
    );
  };

  const beneficiaryName = (rowData) => {
    return (
      <span>{rowData?.beneficiaryName ? rowData?.beneficiaryName : "N/A"}</span>
    );
  };

  const header = renderHeader();

  // ----- Start Code For Geting Table List Data
  const handleData = async () => {
    setLoading(true);
    const DataJsonId ={
      UserID: useId.replace(/"/g, ""),
      Status: "10",
      RoleID: rollId,
      DataType: "All",
      BankID: bankID,
      Days: filterDays,
    }
    await axios
      .post(APIURL + "ImportApplication/ImportPendingReturn", DataJsonId)
      .then((res) => {
        if (res.data.responseCode === "200") {
          setLoading(false);
          setImportPendingReturns(res.data.responseData);
        } else {
          setImportPendingReturns([]);
          setLoading(false);
        }
      }).catch((err)=>{
        const api = "ImportApplication/ImportPendingReturn"
        ErrorlogData(DataJsonId ,err, api )

      });
  };

  useEffect(() => {
    handleData();
  }, [filterDays]);

  // ----- End Code For Geting Table List Data

  // ----- Start Code For Open Poup
  const handleViewData = (id) => {
    setShowUpdateModal(true);
  };
  // ----- End Code For Open Poup

  // ----- Start Code For Geting Table Data
  const GetHandelDetail = async (rbzrefnumber, id) => {
    await axios
      .post(APIURL + "ImportApplication/GetImportRequestInfoByApplicationID", {
        RBZReferenceNumber: `${rbzrefnumber}`,
        ID: id,
      })
      .then((res) => {
        if (res.data.responseCode === "200") {
          setApplicationDetail(res.data.responseData);
        } else {
          setApplicationmessage(res.data.responseMessage);
        }
      })
      .catch((err) => {
        console.log(err);
        const DataJsonId ={
          RBZReferenceNumber: `${rbzrefnumber}`,
          ID: id,
        }
        const api = "ImportApplication/GetImportRequestInfoByApplicationID"
        ErrorlogData(DataJsonId ,err, api )
      });

    await axios
      .post(APIURL + "ImportApplication/GetNewCommentsImport", {
        ID: id,
      })
      .then((res) => {
        if (res.data.responseCode == 200) {
          setallcomment(res.data.responseData);
        } else {
          setallcomment([]);
        }
      })
      .catch((err) => {
        console.log(err);
        const Datajson = {ID: id}
        const api = "ImportApplication/GetNewCommentsImport"
        ErrorlogData(Datajson ,err, api )
      });

    await axios
      .post(APIURL + "ImportApplication/GetImportApplicationHistory", {
        ID: id,
        RoleID: rollId
      })
      .then((res) => {
        if (res.data.responseCode == 200) {
          setTatHistory(res.data.responseData);
        } else {
          setTatHistory([]);
        }
      })
      .catch((err) => {
        console.log(err);
        const DataJsonId = {
          ID: id,
          RoleID: rollId
        }
        const api = "ImportApplication/GetImportApplicationHistory"
        ErrorlogData(DataJsonId ,err, api )
      });

    await axios
      .post(APIURL + "ImportApplication/GetActionsByApplicationID", {
        ID: id,
      })
      .then((res) => {
        if (res.data.responseCode == 200) {
          setActiondata(res.data.responseData);
        } else {
          setActiondata([]);
        }
      })
      .catch((err) => {
        console.log(err);
        const DataId ={ID: id}
        const api = "ImportApplication/GetActionsByApplicationID"
        ErrorlogData(DataId ,err, api )
      });

    // --------------------------vishwas start----------------------------
    await axios
      .post(APIURL + "ImportApplication/GetImportCommentsInfoByRoleID", {
        ApplicationID: id,
      })
      .then((res) => {
        if (res.data.responseCode == 200) {
          setNoDataComment(res.data.responseData);
        } else {
          setNoDataComment([]);
        }
      })
      .catch((err) => {
        console.log(err);
        const Datajson = { ApplicationID: id}
        const api = "ImportApplication/GetImportCommentsInfoByRoleID"
        ErrorlogData(Datajson ,err, api )
      });
    //---------------------------vishwas end------------------------------
  };

  const GetApplicationCount = async (id) => {
    await axios
      .post(APIURL + "ImportApplication/CountByApplicationIDImport", {
        ApplicationID: id,
      })
      .then((res) => {
        if (res.data.responseCode == 200) {
          setresponceCount(res.data.responseData);
        } else {
          setresponceCount([]);
        }
      })
      .catch((err) => {
        console.log(err);
        const Datajson = { ApplicationID: id}
        const api = "ImportApplication/CountByApplicationIDImport"
        ErrorlogData(Datajson ,err, api )
      });
  };

  // ----- End Code For Geting Table Data

  // ----- Start Code For Search Table Data
  const filteredData = ImportPendingReturns?.filter(
    (item) =>
      item?.rbzReferenceNumber
        ?.toLowerCase()
        .includes(searchText?.toLowerCase()) ||
      item?.name?.toLowerCase().toString().includes(searchText) ||
      item?.supervisorName?.toLowerCase().toString().includes(searchText) ||
      item?.amount?.toString().includes(searchText) ||
      item?.statusName?.toLowerCase().toString().includes(searchText) ||
      moment(item?.applicationSubmittedDate)
        .format("DD:MM:YYYY")
        ?.toString()
        .includes(searchText) ||
      item?.applicationType?.toLowerCase().toString().includes(searchText) ||
      item?.currencyName?.toLowerCase().toString().includes(searchText)
  );
  // ----- End Code For Search Table Data

  useEffect(() => {
    handleData();
    setImportPendingReturns([]);
  }, []);

  const supervisorHangechangeBankuser = (e) => {
    const { value } = e.target;
    if (value == "") {
      setAssignUserID("");
      setSupervisorRoleId("");
    } else {
      const { userID, roleID } = JSON?.parse(value);
      setAssignUserID(userID);
      setSupervisorRoleId(roleID);
    }
  };

  const supervisorHangechangeRole = (e) => {
    const { name, value } = e.target;
    if (value == "90A" || value == "") {
      setAssignUserID("");
      setSupervisorRoleId("");
      setAsignUser([]);
    } else {
      const DataJsonId = {
        RoleID:
          value == "10" || value == "40" || value == "25" || value == "30"
            ? parseInt(rollId) + 1
            : value == "15" ||
              value == "5" ||
              value == "6" ||
              value == "7" ||
              value == "8"
            ? value
            : rollId,
        DepartmentID: "2",
        UserID: useId.replace(/"/g, ""),
      }
      axios
        .post(APIURL + "User/GetUsersByRoleID", DataJsonId)
        .then((res) => {
          if (res.data.responseCode == 200) {
            setAsignUser(res.data.responseData);
          } else {
            setSupervisorRoleId("");
          }
        })
        .catch((err) => {
          console.log(err); 
          const api = "User/GetUsersByRoleID"
          ErrorlogData(DataJsonId ,err, api )
        });
    }
  };

  const supervisorHangechange = (e) => {
    const { name, value } = e.target;
    if (value == "") {
      setAssignUserID(null);
    } else {
      setAssignUserID(value);
    }
  };

  const GetRoleHandle = async (id) => {
    setUserrole([]);
    const DataJsonId = {
      RoleID: rollId,
      Status: `${id}`,
      DepartmentID: "7",
    }
    await axios
      .post(APIURL + "Master/GetRoles", DataJsonId)
      .then((res) => {
        if (res.data.responseCode == 200) {
          setUserrole(res.data.responseData);
        } else {
          setUserrole([]);
        }
      })
      .catch((err) => {
        console.log(err);
        const api = "Master/GetRoles"
          ErrorlogData( DataJsonId ,err, api )
      });
  };
  const tabHeader = (
    <div
      className="application-tab w-100 mt-4"
      style={{
        display: "inline-flex",
        justifyContent: "end",
        borderBottom: "2px solid #42a5f5",
      }}
    >
      <div className="form-bx">
        <label className="selectDayBox">
          <select
            className="daySelect"
            aria-label="Large select example"
            onChange={(e) => setFilterDays(e.target.value)}
          >
            <option value="30" selected>
              30 Days
            </option>
            <option value="60">60 Days</option>
            <option value="180">180 Days</option>
            <option value="365">365 Days</option>
          </select>
        </label>
      </div>
    </div>
  );

  return (
    <>
      {tabHeader}
      {loading == true ? (
        <label className="outerloader2">
          <span className="loader"></span>
          <span className="loaderwait">Please Wait...</span>
        </label>
      ) : (
        <>
          <div>
            <DataTable
              value={ImportPendingReturns}
              scrollable
              scrollHeight="600px"
              className="mt-4"
              paginator={ImportPendingReturns.length > 10 ? true : false}
              filters={filters}
              paginatorPosition={"both"}
              paginatorLeft
              rowHover
              rows={10}
              dataKey="id"
              rowsPerPageOptions={[10, 50, 100]}
              globalFilterFields={[
                "rbzReferenceNumber",
                "name",
                "companyName",
                "applicationType",
                "amount",
                "statusName",
              ]}
              emptyMessage="No Data found."
              header={header}
            >
              <Column
                field="rbzReferenceNumber"
                header="RBZ Reference Number"
                sortable
                style={{ width: "220px" }}
              ></Column>
              <Column
                field="companyName"
                header="Applicant Name"
                sortable
                style={{ width: "220px" }}
                body={applicantName}
              ></Column>
              <Column
                field="beneficiaryName"
                header="Beneficiary Name"
                sortable
                style={{ width: "200px" }}
                body={beneficiaryName}
              ></Column>
              <Column
                field="applicationSubmittedDate"
                header="Submitted Date"
                sortable
                style={{ width: "140px" }}
                body={submittedDate}
              ></Column>
              <Column
              field="receivedDate"
              header="Received Date"
              sortable
              style={
                rollId == 2 || rollId == 3
                  ? { display: "none" }
                  : { width: "140px" }
              }
              body={receivedDate}
            ></Column>
              <Column
                field="amount"
                header="Amount"
                sortable
                body={amountwithCurrency}
                style={{ width: "150px" }}
              ></Column>
              <Column
                field="statusName"
                header="Status"
                sortable
                style={{ width: "200px" }}
              ></Column>
              <Column
                field=""
                header="Action"
                style={{ width: "200px" }}
                frozen
                alignFrozen="right"
                body={action}
              ></Column>
            </DataTable>
          </div>

          <Modal
            show={showUpdateModal}
            onHide={handleFormClose}
            backdrop="static"
            className="max-width-600"
          >
            <div className="application-box">
              <div className="login_inner">
                <div className="login_form ">
                  <h5>
                    <Modal.Header closeButton className="p-0">
                      <Modal.Title>
                        View Import Request --{" "}
                        <big>{applicationDetail?.rbzReferenceNumber}</big>
                      </Modal.Title>
                    </Modal.Header>
                  </h5>
                </div>
                <div className="login_form_panel">
                  <Modal.Body className="p-0">
                    <ImportDashboardViewDetails
                      applicationDetail={applicationDetail}
                      handleFormClose={handleFormClose}
                      allcomment={allcomment}
                      noDataComment={noDataComment}
                      tatHistory={tatHistory}
                      Actiondata={Actiondata}
                      responceCount={responceCount}
                    />
                  </Modal.Body>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            show={showEditForm}
            onHide={EditModalClose}
            backdrop="static"
            className="max-width-600"
          >
            <div className="application-box">
              <div className="login_inner">
                <div className="login_form ">
                  <h5>
                    <Modal.Header closeButton className="p-0">
                      <Modal.Title style={{ width: "100%" }}>
                        <div className="row">
                          <div
                            className={
                              applicationDetail?.parentApplicationID == 0 &&
                              applicationDetail?.referredApplicationID == 0
                                ? "col-md-12"
                                : "col-md-6"
                            }
                            style={{ alignItems: "center" }}
                          >
                            Edit{" "}
                            {menuname === "Import" &&
                            submenuname === "Import Pending Returns"
                              ? "Returns"
                              : ""}{" "}
                            Request --{" "}
                            <big>
                              {applicationDetail?.rbzReferenceNumber
                                ? applicationDetail.rbzReferenceNumber
                                : ""}
                            </big>
                          </div>
                        </div>
                      </Modal.Title>
                    </Modal.Header>
                  </h5>
                </div>
                <div className="login_form_panel">
                  <Modal.Body className="p-0">
                    <ImportDashboardRenewEditDetails
                      applicationDetail={applicationDetail}
                      setApplicationDetail={setApplicationDetail}
                      EditModalClose={EditModalClose}
                      setexportdata={setexportdata}
                      handleData={handleData}
                      showdataLoader={showdataLoader}
                      allcomment={allcomment}
                      GetRoleHandle={GetRoleHandle}
                      setapplicationstaus={setapplicationstaus}
                      applicationstaus={applicationstaus}
                      setnextlevelvalue={setnextlevelvalue}
                      nextlevelvalue={nextlevelvalue}
                      asignUser={asignUser}
                      userRole={userRole}
                      responceCount={responceCount}
                      setAsignUser={setAsignUser}
                      supervisorHangechange={supervisorHangechange}
                      supervisorHangechangeBankuser={
                        supervisorHangechangeBankuser
                      }
                      tatHistory={tatHistory}
                      AssignUserID={AssignUserID}
                      setAssignUserID={setAssignUserID}
                      Actiondata={Actiondata}
                      SupervisorRoleId={SupervisorRoleId}
                      supervisorHangechangeRole={supervisorHangechangeRole}
                      setSupervisorRoleId={setSupervisorRoleId}
                      noDataComment={noDataComment}
                      IsDeferred={IsDeferred}
                    />
                  </Modal.Body>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};
export default ImportPendingRetunrsTable;
