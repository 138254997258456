import React from 'react'
import { Helmet } from 'react-helmet'
import AdminDashboardLayout from '../components/AdminDashboardLayout'
import HoliDayTable from '../tables/HoliDayTable'

const HoliDay = () => {
    return (
        <>
            <Helmet><title>Holiday</title></Helmet>
            <AdminDashboardLayout >
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li className="breadcrumb-item active">Holiday</li>
                    </ol>
                </nav>

                <section className="section dashboard adminDashboard">
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='datatable'>
                                <h4 className='section_top_heading'>Holiday</h4>
                                {/* HoliDayTable */}
                                <HoliDayTable />
                            </div>
                        </div>
                    </div>
                </section>
            </AdminDashboardLayout>
        </>
    )
}

export default HoliDay
