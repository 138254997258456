import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DashboardLayout from "../components/DashboardLayout";
import { Helmet } from "react-helmet";
import AllcloseApplicationTable from "../tables/AllcloseApplicationTable";

const AllcloseApplication = () => {
  const [departmentname, setdepartmentname] = useState("");

  useEffect(() => {
    const id = sessionStorage.getItem("closeDepartment");
    setdepartmentname(
      id == "2"
        ? "All Closed Applications"
        : id == "3"
        ? "All Closed Applications"
        : id == "4"
        ? "All Closed Applications"
        : id == "5"
        ? "All Closed Applications"
        : ""
    );
  }, [departmentname]);

  const menuname = sessionStorage.getItem("menuname");
  return (
    <>
      <Helmet>
        <title>{departmentname}</title>
      </Helmet>
      <DashboardLayout>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="">{menuname}</Link>
            </li>
            <li className="breadcrumb-item active">{departmentname}</li>
          </ol>
        </nav>

        <section className="section dashboard">
          <div className="row">
            <div className="col-md-12">
              <div className="datatable">
                <h4 className="section_top_heading">{departmentname}</h4>
                <AllcloseApplicationTable />
              </div>
            </div>
          </div>
        </section>
      </DashboardLayout>
    </>
  );
};

export default AllcloseApplication;