import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from "react-data-table-component";
import axios from "axios";

import moment from "moment";

const TatData = () => {
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [TatData, setTatData] = useState();
  const [CalDATA, setCalDATA] = useState([]);
  // const[yearValue,setYearValue] = useState(null)
  const [yearValue, setYearValue] = useState(new Date())
  const [maxyearValue, setmaxYearValue] = useState(new Date());
  const handleSubmit = async () => {
    if (startTime > endTime) {
      return alert("End Date cannot greater than Start Date");
    }
    if (startTime && endTime) {
      await axios
        .post("https://dmsupgrade.in/API/Master/GetTAT", {
          STARTDATE: moment(startTime)?.format("YYYY-MM-DD HH:mm:ss.SSS"),
          ENDDATE: moment(endTime)?.format("YYYY-MM-DD HH:mm:ss.SSS"),
        })
        .then((res) => {
          // console.log("(res.data.responseData", res.data.responseData);
          if (res.data.responseCode === "200") {
            setTatData(res.data.responseData);
          }
          else {
            setTatData()
          }
        })
        .catch((err) => console.log("error - ", err));
    } else {
      alert("Please select both start and end time.");
    }
  };
  const holiday_listData = async () => {
    try {
      await axios
        // .post("https://dmsupgrade.in/API/Master/GetHolidayList")
        .post("https://dmsupgrade.in/API/Admin/HolidayListByYear", {
          "Year": moment(yearValue).format('yyyy')
        })
        .then((res) => {
          console.log("res---------", res);
          if (res.data.responseCode === "200") {
            setCalDATA(res.data.responseData);
          }
          else {
            setCalDATA([]);
          }
        })

        .catch((err) => console.log("error - ", err));
    } catch (error) {
      console.log("error-----", error);
    }
  }

  console.log("CalDATA--------------", CalDATA);
  const columns = [
    {
      name: "Sr.",
      selector: (row) => row.id,
      sortable: true,
      searchable: true,
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
      searchable: true,
      cell: (row) => <span>{moment(row.date).format("DD MMM YYYY")}</span>,
    },
    {
      name: "Day",
      selector: (row) => row.day,
      sortable: true,
    },
    {
      name: "Details",
      selector: (row) => row.holidayDetails,
      sortable: true,
    },
  ];
  useEffect(() => {
    holiday_listData();
  }, [yearValue]);
  console.log("yearValue-------", yearValue);
  return (
    <>
      <div className=" mt-5">
        <div className="container">
          <div className="row">
            <div className="col-8 card offset-2  p-0 ">
              <h2 className="header-txt">
                Turn-Around-Time (TAT) - Calculations Check
              </h2>
              <div className=" p-3">
                <div className="row mb-4">
                  <div className="col-6 tatdate">
                    <h6>Task Assigned Date/Time</h6>
                  </div>
                  <div className="col-6">
                    <div style={{ position: "relative" }}>
                      <DatePicker
                        placeholderText="Select Start Time"
                        className="tatdatew"
                        // closeOnScroll={(e) => e.target === document}
                        selected={startTime}
                        onChange={(date) => { setStartTime(date); setEndTime(null) }}
                        showTimeInput
                        dropdownMode="select"
                        // minDate={new (Date)}
                        maxDate={new Date(new Date()).setDate(
                          new Date().getDate() + 365
                        )}
                        timeInputLabel="Time:"
                        // showTimeSelect
                        dateFormat="MMMM d, yyyy h:mm aa"
                        showYearDropdown
                      />
                    </div>
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-6 tatdate">
                    <h6>Task Completed Date/Time </h6>
                  </div>
                  <div className="col-6">
                    <div style={{ position: "relative" }}>
                      <DatePicker
                        placeholderText="Select End Time"
                        // closeOnScroll={(e) => e.target === document}
                        className="tatdate"
                        selected={endTime ? endTime : startTime}
                        dropdownMode="select"
                        onChange={(date) => setEndTime(date)}
                        // showTimeSelect
                        showTimeInput
                        timeInputLabel="Time:"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        disabled={!startTime}
                        minDate={startTime}
                        maxDate={new Date(new Date()).setDate(
                          new Date().getDate() + 365
                        )}
                        showYearDropdown
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-2">
                    <button
                      className={
                        TatData !== null ? "btn btn-primary" : "btn btn-success"
                      }
                      style={{ width: "200px" }}
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
                <div className="row my-5 bg-light p-3">
                  <div className="col-6 tatdate">
                    <h6>Calculated TAT </h6>
                  </div>
                  <div className="col-6">
                    {TatData != undefined || TatData != null
                      ? `${TatData?.totalHour} / ${TatData?.totalMinutes} `
                      : "-- / --"}
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-12">
                    <p>
                      <b>Please note: </b>
                      <ol>
                        <li>
                          We have considered the Working hours to be 8:00 am to
                          4:30 pm.
                        </li>
                        <li>
                          For work completed in non-working
                          hours/holidays/weekends, we are adding only additional
                          time of 30 minutes or actual time spent in non-working
                          hours, whichever is less.
                        </li>
                        <li>
                          Following Holidays are listed in the Holiday’s
                          Calendar.
                        </li>
                      </ol>
                    </p>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-md-12">
                    <div className="border border-primary border-4">

                      <DataTable
                        columns={columns}
                        data={CalDATA}
                        persistTableHead={true}
                        // paginationRowsPerPageOptions={[20, 50, 100]}
                        // pagination
                        highlightOnHover
                        className="exporttable "
                        defaultSortFieldId={1}
                        fixedHeader
                        subHeader
                        subHeaderComponent={
                          <div className="d-flex align-items-center justify-content-between tatHolydayList w-100" >
                            <p>
                              Holiday List
                            </p>
                            <div style={{ position: "relative" }}>
                              <DatePicker
                                placeholderText="Select year"
                                selected={yearValue}
                                onChange={(date) => setYearValue(date)}
                                minDate="01/01/2017"
                                maxDate={
                                  new Date(maxyearValue.getTime() + 365 * 24 * 60 * 60 * 1000)
                                }
                                dropdownMode="select"
                                showYearPicker
                                dateFormat="yyyy"
                              />
                            </div>
                          </div>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TatData;
