import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { Storage } from "../login/Storagesetting";
import DashboardLayout from "../components/DashboardLayout";
import FINAdvanceSearchForm from "../components/FINAdvanceSearchForm";
import { Link } from "react-router-dom";
import { APIURL, ImageAPI } from "../constant";
import ErrorsLogApi from "../components/ErrorslogApi";

const FINAdvanceSearch = () => {
  const { ErrorlogData } = ErrorsLogApi();
  const UserID = Storage.getItem("userID");
  const [saveSearchListData, setSaveSearchListData] = useState([]);
  const [saveSearchSelectedValue, setSaveSearchSelectedValue] = useState([]);
  const [saveSearchChangeValue, setSaveSearchChangeValue] = useState('');

  const saveSearchList = async (e) => {
    const DataReferred = {
      UserID: UserID.replace(/"/g, ""),
      DepartmentID: 4,
    }
    await axios
      .post(APIURL + "ReportData/GetsearchRecord", DataReferred)
      .then((res) => {
        if (res.data.responseCode == 200) {
          setSaveSearchListData(res.data.responseData)
        } else {
          setSaveSearchListData([])
        }
      })
      .catch((err) => {
        const api = "ReportData/GetsearchRecord"
        ErrorlogData(DataReferred, err, api)
        console.log(err);
      });
  };

  const handleChangeSelectSave = async (e) => {
    const { name, value } = e.target;
    setSaveSearchChangeValue(value)

    await axios
      .post(APIURL + "ReportData/GetsearchRecordByID", {
        ID: value
      })
      .then((res) => {
        if (res.data.responseCode == 200) {
          const jsonData = JSON.parse(res.data.responseData?.jsonData);
          setSaveSearchSelectedValue(jsonData)
        } else {
          setSaveSearchSelectedValue(res.data.responseData)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const menuname = sessionStorage.getItem("menuname");
  const submenuname = sessionStorage.getItem("submenuname");
  useEffect(() => {
    saveSearchList();
  }, [])
  return (
    <>
      <Helmet>
        {" "}
        <title>
          {menuname ? menuname : "Export"}{" "}
          {submenuname ? submenuname : "Dashboard"}
        </title>{" "}
      </Helmet>
      <DashboardLayout>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/BankADLADashboard">Foreign Investments </Link>
            </li>
            <li className="breadcrumb-item active">Advanced Search</li>
          </ol>
        </nav>

        <section className="section dashboard">
          <div className="row">
            <div className="col-md-12">
              <div className="datatable advance_save">
                <h4 className="section_top_heading">REPORT PARAMETERS
                  <div class="form-bx">
                    <label>
                      <select
                        onChange={(e) => {
                          handleChangeSelectSave(e);
                        }}
                        value={saveSearchChangeValue}
                      >
                        <option selected value=''>Select Saved Search</option>
                        {saveSearchListData?.map((item, index) => {
                          return (
                            <>
                              <option value={item?.id} key={index}>
                                {item.searchName}
                              </option>
                            </>
                          );
                        })}

                      </select>
                    </label>
                  </div>
                </h4>

                {/* <h5 className="section_top_subheading">REPORT PARAMETERS</h5> */}

                <FINAdvanceSearchForm
                  saveSearchSelectedValue={saveSearchSelectedValue}
                  saveSearchList={saveSearchList}
                  setSaveSearchChangeValue={setSaveSearchChangeValue}
                />
              </div>
            </div>
          </div>
        </section>
      </DashboardLayout>
    </>
  );
};

export default FINAdvanceSearch;
