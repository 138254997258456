import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-bootstrap/Modal';
import DataTable from "react-data-table-component";
import { Link } from 'react-router-dom'
import { APIURL } from "./constant";
import axios from "axios";
import moment from "moment";

const IssueLogData = () => {
  const [modalViewshow, setModalViewShow] = useState(false);
  const [loader, setLoader] = useState(false)

  const handlemodalViewClose = () => setModalViewShow(false);
  const handlemodalViewShow = () => setModalViewShow(true);
  const [issueLogData, setIssueLogData] = useState([]);
  const [issueLogViewData, setIssueViewLogData] = useState({});

  const issueLog_listData = async () => {
    setLoader(true)
    try {
      await axios
        .post(APIURL + 'Admin/GetResponse')
        .then((res) => {
          if (res.data.responseCode === "200") {
            setLoader(false)
            setIssueLogData(res.data.responseData);
          }
          else {
            setLoader(false)
            setIssueLogData([]);
          }
        })
        .catch((err) => console.log("error - ", err));
    } catch (error) {
      console.log("error-----", error);
    }
  }
  const handleIssueLogById = async (id) => {
    setLoader(true)
    try {
      await axios
        .post(APIURL + 'Admin/GetIssueDetailsByID', {
          ID: id
        })
        .then((res) => {
          if (res.data.responseCode === "200") {
            setLoader(false)
            setIssueViewLogData(res.data.responseData);
          }
          else {
            setLoader(false)
            setIssueViewLogData([]);
          }
        })
        .catch((err) => console.log("error - ", err));
    } catch (error) {
      console.log("error-----", error);
    }
  }

  const columns = [
    {
      name: "API Name",
      selector: (row) => row.apiName,
      sortable: true,
      searchable: true,
      width: "350px",
    },
    {
      name: "Date",
      selector: (row) => row.issueDate,
      sortable: true,
      searchable: true,
      cell: (row) => <span>{moment(row.issueDate).format("DD MMM YYYY")}</span>,
      width: "150px",
    },
    {
      name: "Error Message",
      selector: (row) => row.exceptionInfo,
      sortable: true,
      width: "500px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      width: "150px",
      cell: (row) => (
        <span>
          {row.status === 1 ? (
            <span className="badge rounded-pill bg-success">Active</span>
          ) : (
            <span className="badge rounded-pill bg-warning text-dark">
              Inactive
            </span>
          )}
        </span>
      ),
    },
    {
      name: "Action",
      width: "100px",
      cell: (row) => (
        <Link to="" className="me-2"
          onClick={() => { handleIssueLogById(row.id, row.name); handlemodalViewShow(); }}>
          <i className="pi pi-eye"></i></Link>
      ),
    },
  ];

  useEffect(() => {
    issueLog_listData();
  }, []);


  return (
    <>
      <div className=" mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12 card p-0">
              <h2 className="header-txt">
                Issue Log List
              </h2>
              <div className=" p-3">
                {loader == true ? (
                  <label className="outerloader2">
                    <span className="loader"></span>
                    <span className="loaderwait">Please Wait...</span>
                  </label>
                ) :
                  <div className="row mb-5">
                    <div className="col-md-12">
                      <div className="border border-primary border-4">

                        <DataTable
                          columns={columns}
                          data={issueLogData}
                          persistTableHead={true}
                          paginationRowsPerPageOptions={[10, 50, 100]}
                          pagination
                          highlightOnHover
                          className="exporttable "
                          // defaultSortFieldId={1}
                          fixedHeader
                        />
                      </div>
                    </div>
                  </div>
                }

                <Modal show={modalViewshow} onHide={handlemodalViewClose} backdrop="static" size="lg">
                  <div className="application-box editmodal-change issueLog-box">
                    <div className="login_inner">
                      <div className="login_form ">
                        <h5> <Modal.Header closeButton className="p-0">
                          <Modal.Title>View Issue </Modal.Title>
                        </Modal.Header></h5></div>
                      <div className="login_form_panel">
                        <Modal.Body className="p-0">
                          {loader == true ? (
                            <label className="outerloader2">
                              <span className="loader"></span>
                              <span className="loaderwait">Please Wait...</span>
                            </label>
                          ) :
                            <div className="row">
                              <div className="col-md-12">
                                <div className="inner_form">
                                  <label className="controlform">
                                    Date
                                  </label>
                                  <div className="form-bx">
                                    <label>
                                      <p disabled>{moment(issueLogViewData.issueDate).format("DD MMM YYYY")}
                                      </p>
                                      <span className="sspan"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="inner_form">
                                  <label className="controlform">
                                    Api Name
                                  </label>
                                  <div className="form-bx">
                                    <label>
                                      <p disabled>{
                                        issueLogViewData?.apiName
                                          ? issueLogViewData?.apiName
                                          : "N/A"
                                      }
                                      </p>
                                      <span className="sspan"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="inner_form">
                                  <label className="controlform">
                                    Error Message
                                  </label>
                                  <div className="form-bx">
                                    <label>
                                      <p disabled>
                                        {
                                          issueLogViewData?.exceptionInfo
                                            ? issueLogViewData?.exceptionInfo
                                            : "N/A"
                                        }
                                      </p>
                                      <span className="sspan"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="inner_form">
                                  <label className="controlform">
                                    Error JSON
                                  </label>
                                  <div className="form-bx">
                                    <label>
                                      <p disabled>
                                        {
                                          issueLogViewData?.jsonSent
                                            ? issueLogViewData?.jsonSent
                                            : "N/A"
                                        }
                                      </p>
                                      <span className="sspan"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                        </Modal.Body>
                      </div>
                    </div>
                  </div>
                </Modal >
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IssueLogData;
