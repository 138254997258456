import React from 'react';
import { Helmet } from 'react-helmet';
import AdminDashboardLayout from '../components/AdminDashboardLayout';
import PendingUserTable from '../tables/PendingUserTable';
import { Storage } from '../../login/Storagesetting';
import DashboardLayout from '../../components/DashboardLayout';
const PendingUser = () => {

  const roleId = Storage.getItem("roleIDs")

  const Layout = roleId === '1' ? AdminDashboardLayout : DashboardLayout;

  return (
    <>
      <Helmet>  <title>Pending User</title>  </Helmet>
      <Layout>

        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
            <li className="breadcrumb-item active">Pending User</li>
          </ol>
        </nav>

        <section className="section dashboard adminDashboard">
          <div className='row'>
            <div className='col-md-12'>
              <div className='datatable'>
                <h4 className='section_top_heading'>Pending User</h4>
                {/* PendingUserTable */}
                <PendingUserTable />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default PendingUser
