import React from 'react';
import PdfTemplate from '../components/PdfTemplate';



const TemplatePagePDF = () => {
    return (

        <PdfTemplate />

    )
}

export default TemplatePagePDF
