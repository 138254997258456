import React from "react";
import { Link ,useNavigate} from "react-router-dom";
import { Storage } from "../login/Storagesetting";
import DashboardLayout from "../components/DashboardLayout";
import FINExpiringRequestsTable from "../tables/FINExpiringRequestsTable";
import FINExpiringRequestsIndividualTable from "../tables/FINExpiringRequestsIndividualTable";
import { Helmet } from "react-helmet";

const FINExpiringRequests = () => {
  const rollId = Storage.getItem("roleIDs");
  const navigate = useNavigate();
  const handleAdvanceSearch = () => {
    navigate("/AdvanceSearchFIN");
  }
  const menuname = sessionStorage.getItem("menuname");
  const submenuname = sessionStorage.getItem("submenuname");
  return (
    <>
      <Helmet>
        <title>
          {menuname} {submenuname}
        </title>
      </Helmet>
      <DashboardLayout>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="">Import</Link>
            </li>
            <li className="breadcrumb-item active">Expiring</li>
          </ol>
        </nav>

        <section className="section dashboard">
          <div className="row">
            <div className="col-md-12">
              <div className="datatable">
                <h4 className="section_top_heading">EXPIRING REQUESTS
                <button
                    type="button"
                    className="adSearchButton"
                    onClick={() => handleAdvanceSearch()}
                  >
                    Advanced Search
                  </button>
                </h4>
                {rollId == 10 || rollId == 11 ? <FINExpiringRequestsIndividualTable/> : <FINExpiringRequestsTable />}
              </div>
            </div>
          </div>
        </section>
      </DashboardLayout>
    </>
  );
};

export default FINExpiringRequests;
