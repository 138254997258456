import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import ErrorsLogApi from './ErrorslogApi';

function ErrorFallback({ error, resetErrorBoundary }) {
  const { ErrorlogData } = ErrorsLogApi();
  const navi = useNavigate()
  const getLocation = window.location.href
  const submitError = () => {
    const api = getLocation
    let DataJons = "Error occure in frontend"
    ErrorlogData(DataJons, error.message, api)
  }

  return (
    <div className='ErrorBoundrybx' role="alert">
      <div className='ErrorBoundrybx_inner'>
        <p>Something went wrong &#128546;</p>
        <pre className='text-danger h3 mb-3'>{error.message}</pre>
        <button onClick={() => { resetErrorBoundary(); submitError(); window.confirm("Somthing went wrong! please try again.", navi("/")) }}>Try again</button>
      </div>
    </div>
  );
}

const MyErrorBoundary = ({ children }) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // Reset any state here if needed 
        //  const api = "ExportApplication/CountData"
        // ErrorlogData(DataJsonCount ,err, api )
      }}
    >
      {children}
    </ErrorBoundary>
  );
}
export default MyErrorBoundary;